import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Screens/Home/Home";
import Hotel from "./Screens/Hotel/Hotel";
import List from "./Screens/List/List";
import Login from "./Screens/Login/Login";
import TermsAndCondition from "./Components/TermsAndCondition/TermsAndCondition";
import Shipping from "./Components/Shipping/Shipping";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Cancellation from "./Components/Cancellation/Cancellation";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import ContactUs from "./Components/ContactUs/ContactUs";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hotels" element={<List />} />
          <Route path="/hotels/:id" element={<Hotel />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cancellation" element={<Cancellation />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <div className="container">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
