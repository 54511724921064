import React, { useEffect } from "react";
import '../TermsAndCondition/TermsAndCondition.css'


const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top on component mount
      }, []);
  return (
    <>
      <div className="container">
        <div className="terms-and-condition">
          <div className="terms-and-condition-head">
            <h1>Privacy Policy </h1>
          </div>
          <div className="terms-and-condition-para">
            {/* <h6>Effective Date: 01/02/2024</h6> */}
            <p>
              Thank you for choosing Mapathink! This Privacy Policy explains how
              Mapathink ("we," "our," or "us") collects, uses, discloses, and
              protects your information when you use our website, mobile
              application, or any other services provided by us (collectively
              referred to as the "Services").
            </p>
            <p>
              By accessing or using our Services, you agree to the terms and
              practices described in this Privacy Policy. Please take a moment
              to review this document.
            </p>
            <h6>1. Information We Collect</h6>
            <h6>1.1 Personal Information</h6>
            <p>
              We may collect personal information that you provide voluntarily
              when using our Services, such as:
              <br />
              Name
              <br />
              Email address
              <br />
              Contact information
              <br />
              Payment details
            </p>

            <h6>1.2 Non-Personal Information</h6>
            <p>
              We may also collect non-personal information automatically when
              you interact with our Services, including:
              <br />
              Device information
              <br />
              IP address
              <br />
              Location information
              <br />
              Usage data
            </p>
            <h6>2. How We Use Your Information</h6>
            <p>
              We may use the collected information for the following purposes:
              <br />
              To provide and maintain our Services
              <br />
              To personalize your experience
              <br />
              To process transactions and payments
              <br />
              To send you relevant updates and promotional materials
              <br />
              To improve our Services and develop new features
            </p>
            <h6>3. Sharing Your Information</h6>
            <p>
              We may share your information with third parties under the
              following circumstances:
              <br />
              With your consent
              <br />
              To comply with legal obligations
              <br />
              To protect our rights, privacy, safety, or property
              <br />
              In the event of a merger, acquisition, or sale of all or part of
              our assets
            </p>
            <h6>4. Cookies and Similar Technologies</h6>
            <p>
              We use cookies and similar technologies to enhance your experience
              and collect data about your interactions with our Services. You
              can manage your cookie preferences through your browser settings.
            </p>

            <h6>5. Security</h6>
            <p>
              We implement reasonable security measures to protect your
              information from unauthorized access, disclosure, alteration, and
              destruction. However, no method of transmission over the internet
              or electronic storage is 100% secure, and we cannot guarantee
              absolute security.
            </p>
            <h6>6. Your Choices</h6>
            <p>
              You can choose not to provide certain information, but it may
              affect your ability to use some features of our Services. You may
              also opt-out of receiving promotional emails from us.
            </p>
          </div>
          <h6>7. Children's Privacy</h6>
          <p>
            Our Services are not intended for children under the age of 13. We
            do not knowingly collect personal information from children. If you
            believe we have inadvertently collected information from a child,
            please contact us immediately.
          </p>
          <h6>8. Changes to this Privacy Policy</h6>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. We will notify you of any changes by posting the new Privacy
            Policy on this page.
          </p>
          <h6>9. Contact Us</h6>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at info@mapathink.com.
          </p>

          <p>
            <strong>
              Thank you for trusting Mapathink with your information. Safe
              travels!
            </strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
