import React, { useEffect } from "react";
import "../TermsAndCondition/TermsAndCondition.css";

const Cancellation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top on component mount
      }, []);
  return (
    <>
      <div className="container">
        <div className="terms-and-condition">
          <div className="terms-and-condition-head">
            <h1>Cancellation and Refund Policy </h1>
          </div>
          <div className="terms-and-condition-para">
            {/* <h6>Effective Date: 01/02/2024</h6> */}
            <p>
              Thank you for choosing Mapathink! This Cancellation and Refund
              Policy outlines the terms and conditions regarding the
              cancellation of bookings and the refund process for the services
              provided by Mapathink (referred to as "us," "we," or "our").
            </p>
            <h6>1. Booking Cancellation</h6>
            <h6>1.1 User-initiated Cancellation</h6>
            <p>
              Users may cancel bookings made through Mapathink subject to the
              specific cancellation policies associated with the service or
              accommodation provider. The cancellation policies may vary, and
              users are encouraged to review the terms and conditions provided
              by the respective service or accommodation provider.
            </p>
            <h6>1.2 Mapathink-initiated Cancellation</h6>
            <p>
              Mapathink reserves the right to cancel bookings or services due to
              unforeseen circumstances, including but not limited to natural
              disasters, government restrictions, or any other events beyond our
              control. In such cases, Mapathink will make reasonable efforts to
              provide alternative solutions or issue refunds as outlined in this
              policy.
            </p>
            <h6>2. Refund Process</h6>
            <h6>2.1 Eligibility for Refunds</h6>
            <p>
              Refunds are subject to the cancellation policies of the specific
              service or accommodation provider. Users are responsible for
              reviewing and understanding the refund eligibility criteria
              associated with their bookings.
            </p>
            <h6>2.2 Refund Method</h6>
            <p>
              Refunds, when applicable, will be processed using the original
              payment method used for the booking. Processing times may vary
              based on the payment provider and financial institutions involved.
            </p>
            <h6>3. Refund Exceptions</h6>
            <h6>3.1 Non-Refundable Bookings</h6>
            <p>
              Certain bookings may be non-refundable or have specific conditions
              that restrict the eligibility for a refund. Users are advised to
              carefully review the terms associated with each booking.
            </p>
            <h6>3.2 Service Disruptions</h6>
            <p>
              Mapathink is not responsible for disruptions or cancellations
              caused by circumstances beyond our control, including but not
              limited to weather conditions, transportation delays, or changes
              in government regulations.
            </p>
            <h6>4. Communication</h6>
            <h6>4.1 Notification of Cancellation</h6>
            <p>
              Users are required to notify Mapathink promptly of any
              cancellation requests. Notification should be made through the
              provided communication channels on the Mapathink platform.
            </p>
          </div>
          <h6>4.2 Communication with Service Providers</h6>
          <p>
            Mapathink will facilitate communication between users and service or
            accommodation providers regarding cancellations and refunds.
            However, the final decision on refunds rests with the respective
            service or accommodation provider.
          </p>
          <h6>5. Changes to the Policy</h6>
          <p>
            Mapathink reserves the right to update or modify this Cancellation
            and Refund Policy at any time. Users will be notified of any changes
            through the Mapathink platform.
          </p>
          <h6>6. Contact Us</h6>
          <p>
            If you have any questions or concerns about our Cancellation and
            Refund Policy, please contact us at info@mapathink.com.
          </p>

          <p>
            <strong>
              Thank you for choosing Mapathink. We hope you have a pleasant
              travel experience!
            </strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default Cancellation;
