import React, { useEffect } from "react";
import "./TermsAndCondition.css";

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container">
        <div className="terms-and-condition">
          <div className="terms-and-condition-head">
            <h1>Terms-and-Condition</h1>
          </div>
          <div className="terms-and-condition-para">
            {/* <h6>Effective Date: 01/02/2024</h6> */}
            <p>
              Welcome to Mapathink! These Terms and Conditions ("Terms") govern
              your use of the Mapathink website, mobile application, and other
              services provided by us (collectively referred to as the
              "Services"). By accessing or using our Services, you agree to
              comply with and be bound by these Terms. If you do not agree with
              these Terms, please do not use our Services.
            </p>
            <h6>1. Acceptance of Terms</h6>
            <p>
              By using our Services, you acknowledge that you have read,
              understood, and agree to be bound by these Terms. If you are using
              the Services on behalf of an organization, you represent and
              warrant that you have the authority to bind that organization to
              these Terms.
            </p>
            <h6>2. Use of Services</h6>
            <p>
              You agree to use our Services for lawful purposes only and in
              accordance with these Terms. You are responsible for all
              activities conducted under your account, and you must not share
              your account credentials with others.
            </p>
            <h6>3. User Content</h6>
            <p>
              By submitting any content, including but not limited to reviews,
              comments, or suggestions, you grant Mapathink a worldwide,
              royalty-free, perpetual, irrevocable, and sublicensable right to
              use, reproduce, modify, adapt, publish, translate, create
              derivative works, distribute, and display such content.
            </p>
            <h6>4. Intellectual Property</h6>
            <p>
              The content, features, and functionality of the Services,
              including but not limited to text, graphics, logos, images, and
              software, are the property of Mapathink and are protected by
              copyright, trademark, and other intellectual property laws.
            </p>
            <h6>5. Prohibited Conduct</h6>
            <p>You agree not to:</p>
            <ul>
              <li>Use the Services for any unlawful purpose</li>
              <li>Engage in any form of harassment or disruptive behaviour</li>
              <li>Violate any applicable laws or regulations</li>
              <li>Attempt to gain unauthorized access to our systems</li>
            </ul>
            <h6>6. Limitation of Liability</h6>
            <p>
              Mapathink and its affiliates, officers, employees, agents,
              partners, and licensors shall not be liable for any indirect,
              incidental, special, consequential, or punitive damages, including
              but not limited to lost profits, data, or use.
            </p>
            <h6>7. Termination</h6>
            <p>
              Mapathink reserves the right to terminate or suspend your access
              to the Services at any time for any reason, without notice.
            </p>
          </div>
          <h6>8. Governing Law</h6>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of India, without regard to its conflict of law principles.
          </p>
          <h6>9. Changes to Terms</h6>
          <p>
            Mapathink reserves the right to update or modify these Terms at any
            time. Continued use of the Services after any such changes shall
            constitute your consent to such changes.
          </p>
          <h6>10. Contact Us</h6>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at info@mapathink.com.
          </p>

          <p>
            <strong>Thank you for choosing Mapathink. Happy travels!</strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
