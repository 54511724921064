import React, { useEffect } from "react";
import '../TermsAndCondition/TermsAndCondition.css';

const Shipping = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top on component mount
      }, []);
  return (
    <>
      <div className="container">
        <div className="terms-and-condition">
          <div className="terms-and-condition-head">
            <h1>Shipping and Delivery Policy for Mapathink</h1>
          </div>
          <div className="terms-and-condition-para">
            {/* <h6>Effective Date: 01/02/2024</h6> */}
            <p>
              Thank you for choosing Mapathink! This Shipping and Delivery
              Policy outlines the terms and conditions regarding the shipping
              and delivery of any physical goods, if applicable, associated with
              the services provided by Mapathink (referred to as "us," "we," or
              "our").
            </p>
            <h6>1. Shipping Information</h6>
            <h6>1.1 Digital Services</h6>
            <p>
              Mapathink primarily offers digital services, and as such, there is
              typically no physical shipping associated with our standard
              offerings. Users will receive relevant information, confirmations,
              and access details electronically through the provided
              communication channels on the Mapathink platform.
            </p>
            <h6>1.2 Physical Goods or Documents</h6>
            <p>
              In the event that Mapathink offers physical goods or documents,
              the shipping information, including estimated delivery times and
              associated costs, will be clearly communicated to users during the
              checkout process. Users are responsible for providing accurate
              shipping details.
            </p>
            <h6>2. Delivery Methods</h6>
            <h6>2.1 Digital Services Delivery</h6>
            <p>
              Digital services will be delivered electronically through the
              Mapathink platform or via email. Users may access and download
              relevant information, tickets, or confirmations directly from
              their accounts.
            </p>
            <h6>2.2 Physical Goods Delivery</h6>
            <p>
              If applicable, physical goods will be delivered through reputable
              shipping carriers. Users will be provided with tracking
              information, where available, to monitor the delivery status.
              Delivery times may vary based on the user's location and the
              shipping carrier's policies.
            </p>
            <h6>3. Delivery Charges</h6>
            <h6>3.1 Digital Services</h6>
            <p>
              No shipping or delivery charges are associated with standard
              digital services. Any additional fees, if applicable, will be
              clearly communicated to users during the booking or checkout
              process.
            </p>

            <h6>3.2 Physical Goods</h6>
            <p>
              Shipping and delivery charges for physical goods will be outlined
              during the checkout process. Users are responsible for reviewing
              and accepting these charges before completing their purchase.
            </p>
            <h6>4. International Shipping</h6>
            <p>
              Mapathink may offer international shipping for physical goods.
              Users are responsible for understanding and complying with any
              customs regulations or import restrictions relevant to their
              location.
            </p>
          </div>
          <h6>5. Delivery Delays</h6>
          <p>
            Mapathink is not responsible for delays in shipping or delivery
            caused by external factors such as customs processing, natural
            disasters, or transportation disruptions. Users will be notified of
            any significant delays and provided with relevant information.
          </p>
          <h6>6. Communication</h6>
          <p>
            Mapathink will communicate relevant shipping and delivery
            information to users through the Mapathink platform, email, or other
            provided communication channels.
          </p>
          <h6>7. Changes to the Policy</h6>
          <p>
            Mapathink reserves the right to update or modify this Shipping and
            Delivery Policy at any time. Users will be notified of any changes
            through the Mapathink platform.
          </p>
          <h6>8. Contact Us</h6>
          <p>
            If you have any questions or concerns about our Shipping and
            Delivery Policy, please contact us at info@mapathink.com.
          </p>
          <p>
            <strong>
              Thank you for choosing Mapathink. We hope you enjoy your travel
              experience!
            </strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default Shipping;
